














import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class Activation extends Vue {
  public user_id = "";
  public number = 0; // 剩余激活名额数量

  getActication() {
    let _this = this;
    this.$api.request({
      url: "balance/user/shop",
      data: { user_id: this.user_id },
      success(res) {
        _this.number = res.data;
      }
    });
  }

  activated() {
    this.user_id = String(this.$route.query.user_id || "");
    this.number = 0;
    this.getActication();
  }
}
